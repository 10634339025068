var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 证书模版 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-input',{attrs:{"placeholder":"请输入模板名称","allowClear":""},model:{value:(_vm.pictureName),callback:function ($$v) {_vm.pictureName=$$v},expression:"pictureName"}}),_c('a-button',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['certif:certiftemp:list']),expression:"['certif:certiftemp:list']"}],staticClass:"btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.onSearch()}}},[_vm._v("搜索")]),_c('a-button',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['certif:certiftemp:add']),expression:"['certif:certiftemp:add']"}],staticClass:"all_boder_btn",staticStyle:{"margin-left":"24px"},on:{"click":function($event){return _vm.goDetail()}}},[_vm._v("新建模版")])]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item,index) { return index; },"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"locale":{ emptyText: '暂无数据' },"pagination":{
        total: _vm.total,
        current: _vm.pageNum,
        defaultPageSize: _vm.pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.pageNum - 1) * _vm.pageSize + i + 1)+" ")])]}},{key:"image",fn:function(item){return [_c('viewer',[_c('img',{staticClass:"pictrue",attrs:{"src":item,"alt":""}})])]}},{key:"operation",fn:function(item){return [_c('div',{staticClass:"btn_router_link"},[_c('a',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['certif:certiftemp:update']),expression:"['certif:certiftemp:update']"}],on:{"click":function($event){return _vm.goDetail(item.pictureId)}}},[_vm._v("编辑 | ")]),_c('a',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['certif:certiftemp:delete']),expression:"['certif:certiftemp:delete']"}],on:{"click":function($event){return _vm.deleteData(item)}}},[_vm._v("删除")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }