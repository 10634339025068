<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        证书模版
      </template>
      <template #input>
        <a-input placeholder="请输入模板名称" v-model="pictureName" allowClear />
        <a-button type="primary" class="btn" @click="onSearch()" v-hasPermi="['certif:certiftemp:list']">搜索</a-button>
        <a-button style="margin-left:24px" class="all_boder_btn" v-hasPermi="['certif:certiftemp:add']" @click="goDetail()">新建模版</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item,index) => index"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :locale="{ emptyText: '暂无数据' }"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNum,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNum - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <!-- 证书模板图片 -->
        <template slot="image" slot-scope="item">
          <viewer>
            <img class="pictrue" :src="item" alt="" />
          </viewer>
        </template>
        <!-- 操作 -->
        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <a v-hasPermi="['certif:certiftemp:update']" @click="goDetail(item.pictureId)">编辑 | </a>
            <a v-hasPermi="['certif:certiftemp:delete']" @click="deleteData(item)">删除</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import HeaderBox from '@/components/HeaderBox.vue'
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "模板名称",
    align: "center",
    dataIndex: "pictureName",
  },
  {
    title: "模板",
    align: "center",
    dataIndex: "previewUrl",
    scopedSlots: { customRender: "image" },
  },
  {
    title: "操作",
    align: "center",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: {HeaderBox},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      loading:false,
      pictureName:'',
      columns,
      total:1,
      pageNum: 1,
      pageSize: 10,
      tableData:[],
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNum = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },
    // 搜索
    onSearch() {
      this.pageNum = 1;
      this.getData();
    },
    // 跳转详情页
    goDetail(pictureId) {
      // this.$router.push('/admin/BasicConfig/CertificateTemplateDetail');
      this.$router.push({ path: "/admin/BasicConfig/CertificateTemplateDetail", query: { pictureId:pictureId} })
    },
    // 获取数据
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/certiftemp/getPictureList",
        method: "get",
        params: {
          pictureName: this.pictureName,
          pageSize: this.pageSize,
          pageNum: this.pageNum,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    // 删除模板
    deleteData(item) {
      let _this = this;
      this.$confirm({
        title: "确认删除该证书模板?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/certiftemp/deletePictureConfig/" + item.pictureId,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.visible = false;
                _this.getData();
              } else {
                _this.$message.error(res.message);
              }
            });
        },
        onCancel() {},
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getData()
   },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.pictrue{
  max-width: 100px;
}
</style>
